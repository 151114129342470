import React, { Component } from "react";
// import { Carousel } from "react-responsive-carousel";
import classes from "./CarouselImages.module.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "animate.css/animate.min.css";
import Slider from "react-slick";
import mono_1 from "../images/Activations/Untitled_1.jpg";
import mono_2 from "../images/Activations/Untitled_2.jpg";
import mono_3 from "../images/Activations/Untitled_3.jpg"
import mono_4 from "../images/Activations/Untitled_4.jpg";
import mono_5 from "../images/Activations/Untitled_5.jpg";

import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIcon from "@material-ui/icons/ArrowForwardIos";
class CarouselImagesActivations extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.number = 2;
  }
  componentDidMount() {
    // let track = this.slider.current.innerSlider.list.querySelector(
    //   ".slick-track"
    // );
    let focusSlider = setTimeout(() => {
      let slide = document.querySelector(".slick-track .slick-slide");
      slide.focus();
    }, 0);
    return () => clearTimeout(focusSlider);
  }

  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
  render() {
    const settings = {
      fade: true,
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 2
    };
    return (
      <div className={classes.carousel} id="start">
        {/* <ScrollAnimation offset="0" animateIn="fadeInLeft" duration='2.4' animateOnce="true" initiallyVisible ={true}> */}
        <Slider ref={c => (this.slider = c)} {...settings}>
          <div className={classes.image_container}>
            <img className={classes.image} src={mono_1} alt="myImage" />
            <div className={classes.h1}>{/* <h1>I'm Abhijit</h1> */}</div>
          </div>

          <div className={classes.image_container}>
            <img className={classes.image} src={mono_2} alt="myImage" />
            <div className={classes.h1}>{/* <h1>I'm Abhijit</h1> */}</div>
          </div>

          <div className={classes.image_container}>
            <img className={classes.image} src={mono_3} alt="myImage" />
            <div className={classes.h1}>{/* <h1>I'm Abhijit</h1> */}</div>
          </div>

          <div className={classes.image_container}>
            <img className={classes.image} src={mono_4} alt="myImage" />
            <div className={classes.h1}>{/* <h1>I'm Abhijit</h1> */}</div>
          </div>

          <div className={classes.image_container}>
            <img className={classes.image} src={mono_5} alt="myImage" />
            <div className={classes.h1}>{/* <h1>I'm Abhijit</h1> */}</div>
          </div>
          <div className={classes.image_container}>
            <h6>Activations (Ongoing)</h6>
            <br></br>
            <p className={classes.para}>
              At a particular period every year, there is a barrage of these temporary structures
              that take over the landscape of my hometown. Some blend right in while others
              just stand as a constant reminder of the contrast in the visual. These architectural
              activations are being looked at here as snippets of everyday fiction which pop up,
              cater to the audience and swiftly disappear again.
            </p>
          </div>
        </Slider>
        <div style={{ textAlign: "left" }} className={classes.arrows}>
          <span className="button" onClick={this.previous}>
            <ArrowBackIcon
              style={{
                fontSize: "small",
                fontStyle: "italic",
                color: "#c4c0c0"
              }}
            />
          </span>

          <span className="button" onClick={this.next}>
            <ArrowForwardIcon
              style={{
                fontSize: "small",
                fontStyle: "italic",
                color: "#c4c0c0"
              }}
            />
          </span>
        </div>
        {/* </ScrollAnimation> */}
      </div>
    );
  }
}
export default CarouselImagesActivations;
