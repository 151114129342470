import React, { Component } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import Sidebar from "./components/sidebar/Sidebar";
import About from "./components/about/About";
import Interest from "./components/skills/Interest";
import CarouselImages from "./components/carousel/CarouselImages.js";
import CarouselImagesActivations from "./components/CarouselImagesActivations/CarouselImages.js"
import CarouselImagesOdyssey from "./components/CarouselImagesOdyssey/CarouselImages.js";
import Splinters from "./components/splinters/index";
import CarouselImagesLearning from "./components/CarouselImagesLearning/CarouselImages.js"
import Home from "./components/home/Home";
import { NavLink } from "react-router-dom";
import mono_1 from "./components/images/AbhijitDebbranding_2.png";
import "./components/sidebar/Sidebar.css";
import MediaQuery from "react-responsive";
import MetaTags from "react-meta-tags";
import CarouselImagesArrays from "./components/CarouselImagesArrays/CarouselImages.js";

class App extends Component {
  componentDidMount() {
    let winWidth = window.innerWidth;
    let threshold = 1024;
    let el = document.getElementById("sidebar");
    if (winWidth > threshold) {
      el.removeAttribute("data-toggle");
      el.removeAttribute("data-target");
    } else {
      el.setAttribute("data-toggle", "collapse");
      el.setAttribute("data-target", "#navbarSupportedContent");
    }
    document.addEventListener("contextmenu", e => {
      e.preventDefault();
    });
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }
  resize() {
    this.setState({ hideNav: window.innerWidth <= 760 });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }

  render() {
    const style = { fontStyle: "oblique", fontWeight: "600" };
    return (
      <Router>
        <div className="App">
          <MetaTags>
            <title>Abhijit Deb</title>
          </MetaTags>
          <div className="side">
            <nav className="navbar side navbar-expand-lg navbar-light p-0">
              <div
                style={{ display: "flex", justifyContent: "space-between" }}
                className="ipad"
              >
                <MediaQuery maxWidth={768}>
                  <h1>
                    <NavLink
                      to="/"
                      className="h1_links"
                      activeStyle={style}
                    // data-target="#navbarSupportedContent"
                    // data-toggle="collapse"
                    >
                      <img
                        src={mono_1}
                        alt="logo"
                        style={{
                          height: "24px",
                          width: "132px",
                          objectFit: "contain"
                        }}
                      />
                    </NavLink>
                  </h1>
                </MediaQuery>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  style={{ zIndex: "1" }}
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
              </div>

              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <div className="sidebar" id="sidebar">
                  <MediaQuery minWidth={1224}>
                    <h1>
                      <NavLink
                        to="/"
                        className="h1_links"
                        activeStyle={style}
                      // data-target="#navbarSupportedContent"
                      // data-toggle="collapse"
                      >
                        <img
                          src={mono_1}
                          alt="logo"
                          style={{
                            height: "24px",
                            width: "132px",
                            objectFit: "contain"
                          }}
                        />
                      </NavLink>
                    </h1>
                  </MediaQuery>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <ul className="sidebar-nav">
                    <li className="sidebar-nav-items">
                      <NavLink smooth to="/odyssey" className="links" activeStyle={style}>
                        an odyssey
                      </NavLink>
                    </li>
                    <li className="sidebar-nav-items">
                      <NavLink smooth to="/activations" className="links" activeStyle={style}>
                        Activations
                      </NavLink>
                    </li>
                    <li className="sidebar-nav-items">
                      <NavLink smooth to="/learningtoletgo" className="links" activeStyle={style}>
                        Learning To Let Go
                      </NavLink>
                    </li>
                    <li className="sidebar-nav-items">
                      <NavLink smooth to="/arrays" className="links" activeStyle={style}>
                        Arrays
                      </NavLink>
                    </li>
                    <li className="sidebar-nav-items">
                      <NavLink
                        smooth
                        to="/monologue"
                        className="links"
                        activeStyle={style}
                      >
                        monologue
                      </NavLink>
                    </li>
                    <br></br>
                    <br></br>
                    <li
                      className="sidebar-nav-items"
                    // data-target="#navbarSupportedContent"
                    // data-toggle="collapse"
                    >
                      <NavLink
                        smooth
                        to="/splinters"
                        className="links"
                        activeStyle={style}
                      >
                        splinters
                      </NavLink>
                    </li>
                    <br></br>
                    <br></br>

                    <li
                      className="sidebar-nav-items"
                    // data-target="#navbarSupportedContent"
                    // data-toggle="collapse"
                    >
                      <NavLink
                        smooth
                        to="/about"
                        className="links"
                        activeStyle={style}
                      >
                        about
                      </NavLink>
                    </li>

                    <li
                      className="sidebar-nav-items"
                    // data-target="#navbarSupportedContent"
                    // data-toggle="collapse"
                    >
                      <NavLink
                        smooth
                        to="/contact"
                        className="links"
                        activeStyle={style}
                      >
                        contact
                      </NavLink>
                    </li>
                  </ul>
                  <div className="flip-card-back">
                    <ul className="sidebar-nav">
                      <li className="sidebar-nav-icons">
                        {" "}
                        <a
                          href="https://www.instagram.com/abhijit.waits"
                          rel="noopener noreferrer"
                          target="_blank"
                          className="links"
                        >
                          <span style={{ color: "black" }}>Instagram</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <footer>
                <p class="copyright"> © Abhijit Deb 2024</p>
                <span class="copyright"> All Rights Reserved</span>
              </footer>
            </nav>
          </div>
          <div className="main" id="navbarSupported">
            <div>
              <Switch>
                <Route exact path="/">
                  <Home />
                </Route>
                <Route exact path="/monologue">
                  <CarouselImages />
                </Route>
                <Route path="/activations">
                  <CarouselImagesActivations />
                </Route>
                <Route path="/learningtoletgo">
                  <CarouselImagesLearning />
                </Route>
                <Route path="/arrays">
                  <CarouselImagesArrays />
                </Route>
                <Route path="/odyssey">
                  <CarouselImagesOdyssey />
                </Route>
                <Route path="/splinters">
                  <Splinters />
                </Route>
                <Route path="/about">
                  <About />
                </Route>
                <Route path="/contact">
                  <Interest />
                </Route>
              </Switch>
            </div>
          </div>
        </div>
      </Router>
    );
  }
}

export default App;
