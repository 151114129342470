import React, { Component } from "react";
import "./index.css";
import mono_1 from "../images/Splinters/_MG_0097_rsz.jpg";
import mono_2 from "../images/Splinters/_MG_1493.jpg";
import mono_3 from "../images/Splinters/_MG_9223_rsz.jpg";
import mono_4 from "../images/Splinters/_MG_9232.jpg";
import mono_5 from "../images/Splinters/3_rsz.jpg";
import mono_6 from "../images/Splinters/120_Maj_UM0074_processesd_rsz.jpg";
import mono_7 from "../images/Splinters/AVI_0615.jpg";
import mono_8 from "../images/Splinters/AVI_8170_rsz.jpg";
// import mono_9 from "../images/Splinters/bif1001_wp_rsz.jpg";
import mono_10 from "../images/Splinters/error_rsz_inv.jpg";
import mono_11 from "../images/Splinters/Gen_04.jpg";
import mono_12 from "../images/Splinters/Gen_06_rescan_21.jpg";
import mono_13 from "../images/Splinters/Gen_07_rescan.jpg";
import mono_14 from "../images/Splinters/IMG_8459_up_rsz.jpg";
import mono_15 from "../images/Splinters/k400at8000022_rsz.jpg";
import mono_16 from "../images/Splinters/k400at8000047_processed_rsz.jpg";
import mono_17 from "../images/Splinters/k400at8000056_trip.jpg";
import mono_18 from "../images/Splinters/k400at8000063_rsz.jpg";
import mono_19 from "../images/Splinters/k400at8000076_rsz.jpg";
import mono_20 from "../images/Splinters/k400at8000079copy.jpg";
// import mono_21 from "../images/Splinters/mixed_collage.jpg";
import mono_22 from "../images/Splinters/PICT0060.jpg";
import mono_23 from "../images/Splinters/ROY07323.JPG";
import mono_24 from "../images/Splinters/TrifectaSS_rsz.png";
import video1 from "../images/Splinters/AOArsz.mp4";
import video2 from "../images/Splinters/intandemrsz.mp4";
import video3 from "../images/Splinters/rummyclubrsz.mp4";
import video4 from "../images/Splinters/trainx4_lbr.mp4";
import MediaQuery from "react-responsive";
const imgUrls = [
  mono_1,
  mono_2,
  mono_3,
  mono_4,
  mono_5,
  mono_6,
  mono_7,
  mono_8,
  // mono_9,
  mono_10,
  mono_11,
  mono_12,
  mono_13,
  mono_14,
  mono_15,
  mono_16,
  mono_17,
  mono_18,
  mono_19,
  mono_20,
  // mono_21,
  mono_22,
  mono_23,
  mono_24
];

class Splinters extends Component {
  constructor(props) {
    super(props);
    this.state = { currentIndex: null };
    this.closeModal = this.closeModal.bind(this);
    this.renderImageContent = this.renderImageContent.bind(this);
  }
  renderImageContent(src, index) {
    return (
      <div>
        <MediaQuery minWidth={769}>
          <div class="img" onClick={e => this.openModal(e, index)}>
            <img src={src} key={src} alt="img" />
          </div>
        </MediaQuery>
        <MediaQuery maxWidth={768}></MediaQuery>
      </div>
    );
  }
  openModal(e, index, isVideo) {
    this.setState({ currentIndex: index, isVideo: isVideo || false });
  }
  closeModal(e) {
    if (e !== undefined) {
      e.preventDefault();
    }
    this.setState({ currentIndex: null });
  }

  render() {
    return (
      <div className="gallery-container">
        <div className="gallery-grid" id="gallery">
          <div class="img one" onClick={e => this.openModal(e, 8)}>
            <img src={imgUrls[7]} key={imgUrls[0]} alt="img" />
          </div>
          <div class="img second" onClick={e => this.openModal(e, 1)}>
            <img
              src={imgUrls[1]}
              style={{ height: "70px", width: "70px" }}
              key={imgUrls[1]}
              alt="img"
            />
          </div>
          <div class="img third" onClick={e => this.openModal(e, 19)}>
            <img
              src={imgUrls[19]}
              style={{ width: "75px" }}
              key={imgUrls[2]}
              alt="img"
            />
          </div>
          <div class="img fourth" onClick={e => this.openModal(e, 3)}>
            <img src={imgUrls[3]} key={imgUrls[3]} alt="img" />
          </div>
          <div class="img fifth " onClick={e => this.openModal(e, 5)}>
            <img src={imgUrls[5]} key={imgUrls[4]} alt="img" />
          </div>
          <div class="img six" onClick={e => this.openModal(e, 16)}>
            <img src={imgUrls[16]} key={imgUrls[5]} alt="img" />
          </div>
          <div
            class="img video1  "
            onClick={e => this.openModal(e, video2, true)}
          >
            <video width="200" height="75" autoPlay loop muted>
              <source src={video2} type="video/mp4" />
            </video>
          </div>
          <div class="img eight" onClick={e => this.openModal(e, 10)}>
            <img
              src={imgUrls[10]}
              style={{ height: "50px", width: "40px" }}
              key={imgUrls[7]}
              alt="img"
            />
          </div>
          <div class="img twelve " onClick={e => this.openModal(e, 19)}>
            <img src={imgUrls[18]} key={imgUrls[7]} alt="img" />
          </div>
          <div
            class="img video2"
            onClick={e => this.openModal(e, video3, true)}
          >
            <video width="75" height="50" autoPlay loop muted>
              <source src={video3} type="video/mp4" />
            </video>
          </div>
          <div class="img eleven" onClick={e => this.openModal(e, 14)}>
            <img src={imgUrls[14]} key={imgUrls[9]} alt="img" />
          </div>
          <div class="img thirteen" onClick={e => this.openModal(e, 15)}>
            <img
              src={imgUrls[15]}
              style={{
                height: "40px",
                width: "80px"
              }}
              key={imgUrls[10]}
              alt="img"
            />
          </div>
          <div class="img ten" onClick={e => this.openModal(e, 11)}>
            <img
              src={imgUrls[11]}
              style={{ height: "85px", width: "60px" }}
              key={imgUrls[11]}
              alt="img"
            />
          </div>
          <div class="img fourteen" onClick={e => this.openModal(e, 12)}>
            <img
              src={imgUrls[12]}
              style={{ height: "50px", width: "75px" }}
              key={imgUrls[0]}
              alt="img"
            />
          </div>
          <MediaQuery minWidth={769}>
            <div class="img ">
              <div style={{ display: "flex" }}>
                <img
                  style={{ height: "50px", width: "50px", margin: "10px" }}
                  src={imgUrls[7]}
                  key={imgUrls[0]}
                  alt="img"
                  onClick={e => this.openModal(e, 7)}
                />
                <img
                  style={{
                    height: "50px",
                    width: "50px",
                    margin: "20px 0px 0px 42px"
                  }}
                  src={imgUrls[19]}
                  key={imgUrls[0]}
                  alt="img"
                  onClick={e => this.openModal(e, 19)}
                />
              </div>
            </div>
          </MediaQuery>
          <MediaQuery maxWidth={768}>
            <div class="img ">
              <img
                style={{
                  height: "50px",
                  width: "50px",
                  margin: "24px 0px 90px"
                }}
                src={imgUrls[8]}
                key={imgUrls[0]}
                alt="img"
                onClick={e => this.openModal(e, 8)}
              />
              <img
                style={{
                  height: "50px",
                  width: "50px"
                }}
                src={imgUrls[19]}
                key={imgUrls[0]}
                alt="img"
                onClick={e => this.openModal(e, 19)}
              />
            </div>
          </MediaQuery>
          <div class="img second " onClick={e => this.openModal(e, 7)}>
            <img
              src={imgUrls[7]}
              style={{ height: "90px", width: "50px" }}
              key={imgUrls[0]}
              alt="img"
            />
          </div>
          <div class="img seven" onClick={e => this.openModal(e, 2)}>
            <img
              src={imgUrls[2]}
              style={{ height: "40px", width: "60px" }}
              key={imgUrls[0]}
              alt="img"
            />
          </div>
          <MediaQuery minWidth={769}>
            <div class="img start">
              <div style={{ display: "flex" }}>
                <img
                  style={{ height: "60px", width: "50px", margin: "10px 0px" }}
                  src={imgUrls[4]}
                  key={imgUrls[0]}
                  alt="img"
                  onClick={e => this.openModal(e, 4)}
                />
                <img
                  style={{
                    height: "65px",
                    width: "40px",
                    margin: "40px 60px 0px 90px"
                  }}
                  src={imgUrls[0]}
                  key={imgUrls[0]}
                  alt="img"
                  onClick={e => this.openModal(e, 0)}
                />
              </div>
            </div>
          </MediaQuery>
          <MediaQuery maxWidth={769}>
            <div class="img start">
              <img
                style={{ height: "60px", width: "50px", margin: "20px 0px" }}
                src={imgUrls[4]}
                key={imgUrls[0]}
                alt="img"
                onClick={e => this.openModal(e, 4)}
              />
              <img
                style={{
                  height: "65px",
                  width: "40px"
                }}
                src={imgUrls[0]}
                key={imgUrls[0]}
                alt="img"
                onClick={e => this.openModal(e, 0)}
              />
            </div>
          </MediaQuery>
          <MediaQuery minWidth={769}>
            {" "}
            <div
              class="img "
              style={{
                padding: "25px 0px 0px 112px"
              }}
              onClick={e => this.openModal(e, video4, true)}
            >
              <video width="100" height="50" autoPlay loop muted>
                <source src={video4} type="video/mp4" />
              </video>
            </div>
          </MediaQuery>
          <MediaQuery maxWidth={768}>
            <div class="img " onClick={e => this.openModal(e, video4, true)}>
              <video width="100" height="50" autoPlay loop muted>
                <source src={video4} type="video/mp4" />
              </video>
            </div>
          </MediaQuery>
          <MediaQuery maxWidth={768}>
            <div class="img six" onClick={e => this.openModal(e, 6)}>
              <img
                src={imgUrls[6]}
                style={{
                  height: "90px",
                  width: "75px"
                }}
                key={imgUrls[0]}
                alt="img"
              />
            </div>
          </MediaQuery>
          <MediaQuery minWidth={769}>
            <div class="img six" onClick={e => this.openModal(e, 6)}>
              <img
                src={imgUrls[6]}
                style={{
                  height: "90px",
                  width: "75px",
                  marginTop: "25px",
                  marginLeft: "-20px"
                }}
                key={imgUrls[0]}
                alt="img"
              />
            </div>
          </MediaQuery>
          <MediaQuery minWidth={769}>
            <div
              class="img  "
              style={{
                padding: "10px 0px 0px 22px"
              }}
              onClick={e => this.openModal(e, video1, true)}
            >
              <video width="100" height="50" autoPlay loop muted>
                <source src={video1} type="video/mp4" />
              </video>
            </div>
          </MediaQuery>
          <MediaQuery maxWidth={768}>
            <div
              class="img  "
              style={{
                marginBottom: "36px"
              }}
              onClick={e => this.openModal(e, video1, true)}
            >
              <video width="100" height="50" autoPlay loop muted>
                <source src={video1} type="video/mp4" />
              </video>
            </div>
          </MediaQuery>
          <div class="img eight " onClick={e => this.openModal(e, 9)}>
            <img
              src={imgUrls[9]}
              style={{ marginTop: "-90px", height: "140px", width: "120px" }}
              key={imgUrls[0]}
              alt="img"
            />
          </div>
          <div class="img second" onClick={e => this.openModal(e, 13)}>
            <img
              src={imgUrls[13]}
              style={{ height: "50px", width: "30px" }}
              key={imgUrls[0]}
              alt="img"
            />
          </div>
          <MediaQuery maxWidth={768}>
            <div class="img eight" onClick={e => this.openModal(e, 17)}>
              <img
                src={imgUrls[17]}
                style={{ height: "120px", width: "100px" }}
                key={imgUrls[0]}
                alt="img"
              />
            </div>
          </MediaQuery>
          <MediaQuery minWidth={769}>
            <div class="img eight" onClick={e => this.openModal(e, 17)}>
              <img
                src={imgUrls[17]}
                style={{ height: "120px", width: "100px", marginTop: "-75px" }}
                key={imgUrls[0]}
                alt="img"
              />
            </div>
          </MediaQuery>
          <MediaQuery maxWidth={768}>
            <div class="img second" onClick={e => this.openModal(e, 19)}>
              <img
                src={imgUrls[19]}
                style={{ height: "30px", width: "90px" }}
                key={imgUrls[0]}
                alt="img"
              />
            </div>
          </MediaQuery>
          <MediaQuery minWidth={769}>
            <div class="img second" onClick={e => this.openModal(e, 19)}>
              <img
                src={imgUrls[19]}
                style={{ height: "30px", width: "90px", marginLeft: "-40px" }}
                key={imgUrls[0]}
                alt="img"
              />
            </div>
          </MediaQuery>
        </div>
        <GalleryModal
          closeModal={this.closeModal}
          src={
            this.state.isVideo
              ? this.state.currentIndex
              : imgUrls[this.state.currentIndex]
          }
          isVideo={this.state.isVideo}
        />
      </div>
    );
  }
}
class GalleryModal extends React.Component {
  render() {
    const { closeModal, src, isVideo } = this.props;
    if (!src) {
      console.log("whut");
      return null;
    }
    return (
      <div>
        <div isOpen={!!src} className="modal-overlay" onClick={closeModal}>
          <div className="modal-body">
            <div style={{ display: "flex", height: "90vh", width: "100%" }}>
              {!isVideo && <img src={src} alt="img" className="modal-image" />}
              {isVideo && (
                <video width="100%" autoPlay loop>
                  <source src={src} type="video/mp4" />
                </video>
              )}
              {/* <div
                onClick={closeModal}
                style={{
                  cursor: "pointer",
                  color: "white"
                }}
              >
                close
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Splinters;
