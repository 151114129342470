import React, { Component } from "react";
// import { Carousel } from "react-responsive-carousel";
import classes from "./CarouselImages.module.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "animate.css/animate.min.css";
import Slider from "react-slick";
import mono_1 from "../images/Monologue/m1.jpg";
import mono_2 from "../images/Monologue/m2.jpg";
import mono_3 from "../images/Monologue/m3.jpg";
import mono_4 from "../images/Monologue/m4.jpg";
import mono_5 from "../images/Monologue/m5.jpg";
import mono_6 from "../images/Monologue/m6.jpg";
import mono_7 from "../images/Monologue/m7.jpg";
import mono_8 from "../images/Monologue/m8.jpg";
import mono_9 from "../images/Monologue/m9.jpg";
import mono_10 from "../images/Monologue/m10.jpg";
import mono_11 from "../images/Monologue/m11.jpg";
import mono_12 from "../images/Monologue/m12.jpg";
import mono_13 from "../images/Monologue/m13.jpg";
import mono_14 from "../images/Monologue/m14.jpg";
import mono_15 from "../images/Monologue/m15.jpg";
import mono_16 from "../images/Monologue/m16.jpg";
import mono_17 from "../images/Monologue/m17.jpg";
import mono_18 from "../images/Monologue/m18.jpg";
import mono_19 from "../images/Monologue/m19.jpg";
import mono_20 from "../images/Monologue/m20.jpg";
import mono_21 from "../images/Monologue/m21.jpg";
import mono_22 from "../images/Monologue/m22.jpg";
import mono_23 from "../images/Monologue/m23.jpg";
import mono_24 from "../images/Monologue/m24.jpg";
import mono_25 from "../images/Monologue/m25.jpg";
import mono_26 from "../images/Monologue/m26.jpg";
import mono_27 from "../images/Monologue/m27.jpg";
import MediaQuery from "react-responsive";
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIcon from "@material-ui/icons/ArrowForwardIos";
class CarouselImages extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }

  componentDidMount() {
    // let track = this.slider.current.innerSlider.list.querySelector(
    //   ".slick-track"
    // );
    let focusSlider = setTimeout(() => {
      let slide = document.querySelector(".slick-track .slick-slide");
      slide.focus();
    }, 0);
    return () => clearTimeout(focusSlider);
  }

  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
  render() {
    const settings = {
      fade: true,
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 2
    };
    return (
      <div className={classes.carousel} id="start">
        {/* <ScrollAnimation offset="0" animateIn="fadeInLeft" duration='2.4' animateOnce="true" initiallyVisible ={true}> */}
        <Slider ref={c => (this.slider = c)} {...settings}>
          <div className={classes.image_container}>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <p className={classes.para2}>
              There is only a finite amount of things one can remember . So,
            </p>

            <p className={classes.para1}>
              What does one remember?
              <br></br>
              What is worth remembering?
            </p>
          </div>
          <div className={classes.image_container}>
            <img className={classes.image} src={mono_1} alt="myImage" />
          </div>

          <div className={classes.image_container}>
            <img className={classes.image} src={mono_2} alt="myImage" />
          </div>

          <div className={classes.image_container}>
            <img className={classes.image} src={mono_3} alt="myImage" />
          </div>

          <div className={classes.image_container}>
            <img className={classes.image} src={mono_4} alt="myImage" />
          </div>

          <div className={classes.image_container}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <MediaQuery minWidth={769}>
                <img className={classes.image} src={mono_4} alt="myImage" />
                <img
                  className={classes.image}
                  style={{ margin: "0px 100px" }}
                  src={mono_5}
                  alt="myImage"
                />
              </MediaQuery>
              <MediaQuery maxWidth={768}>
                <img className={classes.image} src={mono_5} alt="myImage" />
              </MediaQuery>
            </div>
          </div>

          <div className={classes.image_container}>
            <img className={classes.image} src={mono_6} alt="myImage" />
          </div>

          <div className={classes.image_container}>
            <img className={classes.image} src={mono_7} alt="myImage" />
          </div>
          <div className={classes.image_container}>
            <img className={classes.image} src={mono_8} alt="myImage" />
          </div>

          <div className={classes.image_container}>
            <img className={classes.image} src={mono_9} alt="myImage" />
          </div>

          <div className={classes.image_container}>
            <img className={classes.image} src={mono_10} alt="myImage" />
          </div>

          <div className={classes.image_container}>
            <img className={classes.image} src={mono_11} alt="myImage" />
          </div>

          <div className={classes.image_container}>
            <img className={classes.image} src={mono_12} alt="myImage" />
          </div>

          <div className={classes.image_container}>
            <img className={classes.image} src={mono_13} alt="myImage" />
          </div>

          <div className={classes.image_container}>
            <img className={classes.image} src={mono_14} alt="myImage" />
          </div>

          <div className={classes.image_container}>
            <img className={classes.image} src={mono_15} alt="myImage" />
          </div>

          <div className={classes.image_container}>
            <img className={classes.image} src={mono_16} alt="myImage" />
          </div>

          <div className={classes.image_container}>
            <img className={classes.image} src={mono_17} alt="myImage" />
          </div>

          <div className={classes.image_container}>
            <img className={classes.image} src={mono_18} alt="myImage" />
          </div>

          <div className={classes.image_container}>
            <img className={classes.image} src={mono_19} alt="myImage" />
          </div>

          <div className={classes.image_container}>
            <img className={classes.image} src={mono_20} alt="myImage" />
          </div>
          <div className={classes.image_container}>
            <img className={classes.image} src={mono_21} alt="myImage" />
          </div>
          <div className={classes.image_container}>
            <img className={classes.image} src={mono_22} alt="myImage" />
          </div>
          <div className={classes.image_container}>
            <img
              className={classes.image}
              src={mono_23}
              style={{ width: "300px", height: "300px" }}
              alt="myImage"
            />
          </div>
          <div className={classes.image_container}>
            <div style={{ display: "flex" }}>
              <MediaQuery minWidth={769}>
                <img
                  className={classes.image}
                  style={{ width: "300px", height: "300px" }}
                  src={mono_23}
                  alt="myImage"
                />
                <img
                  className={classes.image}
                  style={{
                    margin: "0px 50px",
                    width: "300px",
                    height: "300px"
                  }}
                  src={mono_24}
                  alt="myImage"
                />
              </MediaQuery>
              <MediaQuery maxWidth={768}>
                <img
                  className={classes.image}
                  style={{ width: "300px", height: "300px" }}
                  src={mono_24}
                  alt="myImage"
                />
              </MediaQuery>
            </div>
          </div>
          <div className={classes.image_container}>
            <div style={{ display: "flex" }}>
              <MediaQuery minWidth={769}>
                <img
                  className={classes.image}
                  style={{ width: "300px", height: "300px" }}
                  src={mono_23}
                  alt="myImage"
                />
                <img
                  className={classes.image}
                  style={{
                    margin: "0px 50px",
                    width: "300px",
                    height: "300px"
                  }}
                  src={mono_24}
                  alt="myImage"
                />
                <img
                  className={classes.image}
                  style={{ width: "300px", height: "300px" }}
                  src={mono_25}
                  alt="myImage"
                />
              </MediaQuery>
              <MediaQuery maxWidth={768}>
                <img
                  className={classes.image}
                  style={{ width: "300px", height: "300px" }}
                  src={mono_25}
                  alt="myImage"
                />
              </MediaQuery>
            </div>
          </div>
          <div className={classes.image_container}>
            <img className={classes.image} src={mono_26} alt="myImage" />
          </div>
          <div className={classes.image_container}>
            <img className={classes.image} src={mono_27} alt="myImage" />
          </div>

          <div className={classes.image_container}>
            <h6>MONOLOGUE</h6>
            <br></br>
            <p className={classes.para}>
              The idea of looking back itself is a comment on the present.
              Either it’s distressing, hence one looks into the lived experience
              of the better days, or rather, it’s less distressing at the
              present, to which one revisits the unpleasant days as a sense of
              accomplishment.
              <br></br>
              <br></br>
              All in all, looking back is like breathing, mostly you don’t
              acknowledge it- you just do it.
              <br></br>
              <br></br>
              Monologue is a fallacy constructed with bits of lived experiences
              mixed together with experiments and fiction which functions like a
              memory recalibration tool for me.
            </p>
          </div>
        </Slider>
        <div style={{ textAlign: "left" }} className={classes.arrows}>
          <span className="button" onClick={this.previous}>
            <ArrowBackIcon
              style={{
                fontSize: "small",
                fontStyle: "italic",
                color: "#c4c0c0"
              }}
            />
          </span>

          <span className="button" onClick={this.next}>
            <ArrowForwardIcon
              style={{
                fontSize: "small",
                fontStyle: "italic",
                color: "#c4c0c0"
              }}
            />
          </span>
        </div>
        {/* </ScrollAnimation> */}
      </div>
    );
  }
}
export default CarouselImages;
