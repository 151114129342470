import React, { Component } from "react";
import classes from "./Home.module.css";
import "animate.css/animate.min.css";
import mono_1 from "../images/landing page.jpg";
class Home extends Component {
  render() {
    return (
      <div className={classes.box} id="about">
        <img className={classes.image} src={mono_1} alt="home"></img>
      </div>
    );
  }
}

export default Home;
