import React, { Component } from "react";
import classes from "./About.module.css";
import mono_1 from "../images/self_new.jpg";
import "animate.css/animate.min.css";

class About extends Component {
  render() {
    return (
      <div className={classes.box} id="about">
        {/* <ScrollAnimation
          offset="0"
          animateIn="fadeInLeft"
          duration="2.4"
          animateOnce="true"
          initiallyVisible={true}
        > */}

        <div className={classes.About}>
          <img className={classes.image} src={mono_1} alt="self"></img>
          <br></br>
          <br></br>
          <p className={classes.para}>
            {" "}
            Abhijit Deb is a self taught photographer born and brought up in
            Agartala, India.
            <br></br>
            <br></br>
            Through a holistic approach, avenues of his practice oscillate from
            fiction and reality with quasi-fiction right in the middle. He likes
            to believe that he is constantly working towards a singular body of
            work which is collated from the outcomes of his practice, one which
            is envisioned by him as sort of a Gesamtkuntswerk.
            <br></br>
            <br></br>
            He is the recipient of the Der Greif Guest Room Scholarship (2023) and the
            Generator Grant by Experimenter Gallery (2021) for his ongoing body of work titled
            an odyssey.
          </p>
        </div>
        {/* </ScrollAnimation> */}
      </div>
    );
  }
}

export default About;
