import React, { Component } from "react";
import classes from "./Interest.module.css";
// import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";

class Interest extends Component {
  render() {
    return (
      <div className={classes.box} id="about">
        <div className={classes.About}>
          <p>
            {" "}
            For commissions & print enquiries:<br></br>
            <br></br> <b>abhijit.deb208@gmail.com</b>
          </p>
        </div>
      </div>
    );
  }
}

export default Interest;
