import React, { Component } from "react";
// import { Carousel } from "react-responsive-carousel";
import classes from "./CarouselImages.module.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "animate.css/animate.min.css";
import Slider from "react-slick";
import mono_1 from "../images/odyssey/1.jpg";
import mono_2 from "../images/odyssey/2.jpg";
import mono_3 from "../images/odyssey/3.jpg";
import mono_4 from "../images/odyssey/4.jpg";
import mono_5 from "../images/odyssey/5.jpg";
import mono_6 from "../images/odyssey/6.jpg";
import mono_7 from "../images/odyssey/7.jpg";
import mono_8 from "../images/odyssey/8.jpg";
import mono_9 from "../images/odyssey/9.jpg";
import mono_10 from "../images/odyssey/10.jpg";
import mono_11 from "../images/odyssey/11.jpg";
import mono_12 from "../images/odyssey/12.jpg";
import mono_13 from "../images/odyssey/13.jpg";
import mono_14 from "../images/odyssey/14.jpg";
import mono_15 from "../images/odyssey/15.jpg";
import mono_16 from "../images/odyssey/16.jpg";
import mono_17 from "../images/odyssey/17.jpg";
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIcon from "@material-ui/icons/ArrowForwardIos";
import MetaTags from "react-meta-tags";
// import ScrollAnimation from "react-animate-on-scroll";
// import Delayed from "./Delayed.js";
class CarouselImagesOdyssey extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  componentDidMount() {
    // let track = this.slider.current.innerSlider.list.querySelector(
    //   ".slick-track"
    // );
    let focusSlider = setTimeout(() => {
      let slide = document.querySelector(".slick-track .slick-slide");
      slide.focus();
    }, 0);
    return () => clearTimeout(focusSlider);
  }

  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
  render() {
    const settings = {
      fade: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
      <div className={classes.carousel} id="start">
        <MetaTags>
          <meta
            name="description"
            content="Abhijit + Deb ,Agartala,Oral literature,Tripura,Assam,Majuli,Brahmaputra,Mising,Naga,Manipur,Nagaland,Mizoram,Bru,Kokborok,Borok,Konyak,Ao,Khasi,Garo,Shillong,Darjeeling,Kalimpong,Type 1 diabetes,Folklore,Folk story,Folk,Sikkim,Himalayas ,Personal work"
          />
        </MetaTags>
        <Slider
          ref={c => (this.slider = c)}
          {...settings}
          className={classes.inner}
        >
          <div className={classes.image_container}>
            <img className={classes.image} src={mono_1} alt="myImage" />
          </div>
          <div className={classes.image_container}>
            <img className={classes.image} src={mono_2} alt="myImage" />
          </div>
          <div className={classes.image_container}>
            <img className={classes.image} src={mono_3} alt="myImage" />
          </div>
          <div className={classes.image_container}>
            <img className={classes.image} src={mono_4} alt="myImage" />
          </div>
          <div className={classes.image_container}>
            <img className={classes.image} src={mono_5} alt="myImage" />
          </div>
          <div className={classes.image_container}>
            <img className={classes.image} src={mono_6} alt="myImage" />
          </div>
          <div className={classes.image_container}>
            <img className={classes.image} src={mono_7} alt="myImage" />
          </div>
          <div className={classes.image_container}>
            <img className={classes.image} src={mono_8} alt="myImage" />
          </div>
          <div className={classes.image_container}>
            <img className={classes.image} src={mono_9} alt="myImage" />
          </div>
          <div className={classes.image_container}>
            <img className={classes.image} src={mono_10} alt="myImage" />
          </div>
          <div className={classes.image_container}>
            <img className={classes.image} src={mono_11} alt="myImage" />
          </div>
          <div className={classes.image_container}>
            <img className={classes.image} src={mono_12} alt="myImage" />
          </div>
          <div className={classes.image_container}>
            <img className={classes.image} src={mono_13} alt="myImage" />
          </div>
          <div className={classes.image_container}>
            <img className={classes.image} src={mono_14} alt="myImage" />
          </div>
          <div className={classes.image_container}>
            <img className={classes.image} src={mono_15} alt="myImage" />
          </div>
          <div className={classes.image_container}>
            <img className={classes.image} src={mono_16} alt="myImage" />
          </div>
          <div className={classes.image_container}>
            <img className={classes.image} src={mono_17} alt="myImage" />
          </div>
          <div className={classes.image_container1}>
            <h6 className={classes.heading}>AN ODYSSEY (ongoing)</h6>
            <br></br>
            <p className={classes.para}>
              In the land of ghastly smokes and dying rivers, a rumour flew like
              a lonesome leaf in an autumn morning. There was a certain whisper
              about a probable paradise that existed somewhere. This land was
              nothing short of materialisation of the fables of ancient times.
              Some said that the green in the woods is like the panacea for the
              eyes, the omnipresent gush of juniper scented air scintillates
              one’s body in every breath, while some said the water runs through
              the river like molten crystal and reveals its heart as clear as
              day.<br></br>
              <br></br> The rumour didn't escape Jwi and the other rivers. She
              was one of the many rivers that were almost reduced to streamlets;
              the water in her almost dried out and the life within her was at
              the cusp of eradication. Nevertheless, Koju, a young man from the
              region was in unconditional love with her. One afternoon, when he
              was lying with her, resting his head on the weary lap of Jwi, she
              brought forward a proposition: she wanted to elope with Koju to
              the so-called paradise. She said, ”Find me that mystical land if
              you may and if you succeed, I shall defy the very fabric of nature
              and change my course to be with you!”  The drive for her in Koju
              made him agree to it in just a heartbeat. The chap, oozing with
              the thrill of being with his beloved in paradise, wanted to start
              at the first light of the next day. Thusly, he bid farewell to Jwi
              and promised to return only after uncovering the location of this
              paradise.
            </p>
          </div>
        </Slider>
        <div style={{ textAlign: "left" }} className={classes.arrows}>
          <span className="button" onClick={this.previous}>
            <ArrowBackIcon
              style={{
                fontSize: "small",
                fontStyle: "italic",
                color: "#c4c0c0"
              }}
            />
          </span>

          <span className="button" onClick={this.next}>
            <ArrowForwardIcon
              style={{
                fontSize: "small",
                fontStyle: "italic",
                color: "#c4c0c0"
              }}
            />
          </span>
        </div>
      </div>
    );
  }
}
export default CarouselImagesOdyssey;
